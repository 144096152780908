import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.has)?_c('div',[_c('div',{staticClass:"text-h4 font-weight-bold mt-4"},[_c('span',[_vm._v(_vm._s(_vm.$t("groups.company")))])]),_c('div',{staticClass:"d-flex"},[_c(VBtn,{staticClass:"mb-3 ml-auto",attrs:{"text":"","small":"","loading":_vm.loadingCompanyGroups,"disabled":_vm.loadingCompanyGroups},on:{"click":_vm.get}},[_vm._v(" "+_vm._s(_vm.$t("global.refresh"))+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1),_vm._l((_vm.groupedCompanyGroups),function(subGroups,prefix){return _c('div',{key:prefix},[(subGroups.length > 0)?_c(VExpansionPanels,[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('h5',{staticClass:"font-weight-bold mb-2 custom-title"},[_vm._v(_vm._s(prefix))])]),_c(VExpansionPanelContent,_vm._l((subGroups),function(group,index){return _c(VCard,{key:index,staticClass:"mb-2 pa-3",attrs:{"elevation":"1"}},[_c('CompanyGroupItem',{attrs:{"group":group,"course-name":_vm.getMainGroupName(group.Name)}})],1)}),1)],1)],1):_vm._e()],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }