















































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { Group } from "@/core/models";
import GroupItem from "./GroupItem.vue";
import CompanyGroupItem from "./CompanyGroupItem.vue";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    GroupItem,
    CompanyGroupItem,
  },
})
export default class CompanyGroups extends Vue {
  @Getter("groups/loadingCompanyGroups") loadingCompanyGroups!: boolean;
  @Getter("groups/getCompanyGroups") companyGroups!: Group[];
  @Getter("groups/retrievedCompanyGroups") retrievedCompanyGroups!: boolean;
  @Action("groups/getCompanyGroups") getCompanyGroups!: () => Promise<void>;

  get has() {
    console.log("this.companyGroups.length: ", this.companyGroups.length);
    return this.companyGroups.length > 0;
  }

  get groupedCompanyGroups() {
    return this.companyGroups.reduce((groups, group) => {
      // Vereinheitliche Bindestriche (einschließlich en Dash und em Dash)
      const normalized = group.Name.replace(/[\u2013\u2014-]/g, " - ");

      // Extrahiere den Kursnamen (Teil vor dem ersten Bindestrich)
      const courseName = normalized.split(" - ")[0];

      // Setze den Kursnamen als Gruppennamen
      if (!groups[courseName]) groups[courseName] = [];

      // Füge die Gruppe zum entsprechenden Kursnamen hinzu
      groups[courseName].push(group);

      return groups;
    }, {} as Record<string, Group[]>);
  }

  // Gibt nur den Kursnamen zurück (Teil nach dem Bindestrich)
  getMainGroupName(name: string) {
    const normalized = name.replace(/[\u2013\u2014-]/g, "-");
    const parts = normalized.split(" - ");
    return parts.length > 1 ? parts[1].trim() : name; // Kursname (Teil nach Bindestrich)
  }

  get() {
    this.getCompanyGroups();
  }

  created() {
    if (!this.retrievedCompanyGroups) this.getCompanyGroups();
  }
}
