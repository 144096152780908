


































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { Group } from "@/core/models";
import GroupItem from "./GroupItem.vue";
import PublicGroupItem from "./PublicGroupItem.vue";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    GroupItem,
    PublicGroupItem,
  },
})
export default class PublicGroups extends Vue {
  @Getter("groups/loadingPublicGroups") loadingPublicGroups!: boolean;
  @Getter("groups/getPublicGroups") publicGroups!: Group[];
  @Getter("groups/retrievedPublicGroups") retrievedPublicGroups!: boolean;
  @Action("groups/getPublicGroups") getPublicGroups!: () => Promise<void>;

  get has() {
    return this.publicGroups.length > 0;
  }

  get() {
    this.getPublicGroups();
  }

  created() {
    if (!this.retrievedPublicGroups) this.getPublicGroups();
  }
}
